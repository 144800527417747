<template>
  <div>
    <video ref="video" controls width="400" height="360" v-if="theUrl">
      <source :src="'https://api.my.ug.edu.ge/api/openVideo?filePath='+theUrl+'&token='+token" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </div>
</template>

<script>
import ScheduleResource from "../../api/scheduleResource";

const scheduleResource = new ScheduleResource();

export default {
  data() {
    return {
      
      theUrl:null,
      token:null
    };
  },

  props: ["video", "actualurl"],

  watch: {
    video(url) {
      if (url === this.actualurl) {
        
        
        this.theUrl=url
        this.token=localStorage.getItem('token')
      }
    },
  },

 
};
</script>
