<template>
  <b-modal
    @shown="onShown()"
    :hide-header="true"
    :hide-footer="true"
    content-class="modal-blue"
    id="resource-popup"
    size="md"
    centered>
    <!-- Modal content-->
    <div class="row ">
      <div class="col mb-5 pb-5 ">
        <div class="registration-group-table">
          <sk-list v-if="loading" height="0.02" :items-count="4"></sk-list>
          <table v-else class="table table-responsive-lg text-left">
            <thead>
            <tr class="mb-5">
              <th>{{ $t('schedule.reference-popup.video-reading-materials') }}</th>
            </tr>
            </thead>
            <tbody>
            <tr class="border-bottom" v-for="(resource, i) of resources">
              <td>
                <a v-if="resource.type.resource_typeeng === 'Reading Material'" target="_blank" :href="resource.resource_link">
                  <img class="img-fluid" src="static/images/icons/book.png" />
                  {{ $t('schedule.reference-popup.reading-material') }} #{{ i + 1 }}
                </a>
                <p @click="openVideo(resource.resource_link)" v-else-if="resource.type.resource_typeeng === 'Video Material'" >
                  <img class="img-fluid" src="static/images/icons/play.png" />
                  {{ $t('schedule.reference-popup.video-lesson') }} #{{ i + 1 }}
                  <VideoPlayer v-show="url==resource.resource_link" :video="url" :actualurl="resource.resource_link"/>
                </p>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col mt-0 pt-3 mt-sm-5 pt-sm-5 mb-3">
        <button type="button" class="btn btn-light-bold" @click="hideModal()">{{ $t('registration.close') }}</button>
      </div>
    </div>
  </b-modal>
</template>

<script>
  import SkList from '../../components/skeletons/sk-list';
  import ScheduleResource from '../../api/scheduleResource';
  import VideoPlayer from './VideoPlayer.vue';

  const scheduleResource = new ScheduleResource();

  export default {
    name: 'resource-popup',

    props: ['schedule'],

    components: { SkList,VideoPlayer },

    data() {
      return {
        resources: [],
        url:null,
        loading: false,
      }
    },

    methods: {

      openVideo(filePath){

     
        this.url=filePath

       
      },


      onShown() {
        this.loadResources();
      },

      hideModal() {
        this.$bvModal.hide('reference-popup');
      },

      loadResources() {
        this.loading = true;
        scheduleResource.resource(this.schedule.subjectID)
        .then((response) => {
        
          this.resources = response.data.data;
          this.loading = false;
        })
      }
    }
  };
</script>

<style scoped>
  .img-fluid {
    width: 24px;
    height: 24px;
  }
</style>
